.exp__sec {
  width: 100%;
  margin-top: 5%;
  padding: 0 10% 5%;

  .exp {
    @extend %card-style;
    padding: 5%;
    font-family: $fontEn1, $fontCn;

    h1 {
      margin-top: 0;
    }

    .exp__container {
      height: 70vh;
      position: relative;
      overflow: hidden;

      &::before {
        opacity: var(--before-opacity);
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 3rem;
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }

      &::after {
        content: "";
        z-index: 1;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 4rem;
        background: rgb(255, 255, 255);
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
      }

      .exp__content {
        height: 70vh;
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
          display: none;
        }

        ul {
          padding: 0;
          margin: 0;

          li {
            list-style: none;
            padding-left: 0;
          }
        }

        .exp__item {
          display: flex;
          font-size: 1.25rem;
          width: 100%;
          margin-bottom: 2.5rem;

          .exp__date {
            width: 24%;
            padding-right: 2%;
            border-right: 2px solid #333;
          }

          .exp__description {
            padding-left: 2%;
            width: 72%;

            h3 {
              margin: 0;
            }

            p {
              margin: 0 0 1rem 0;
              color: #777;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .exp__sec {
    padding: 0 0 5% 0;

    .exp {
      background-color: rgba(250, 128, 114, 0) !important;
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0) !important;
      padding: 0;

      h1 {
        width: 80%;
        margin: 1rem auto;
        background-color: #fff;
        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
        border-radius: 1.5rem;
        box-sizing: border-box;
        padding: 0.25rem 0.5rem 0.25rem 1rem;
      }

      .exp__container {
        height: fit-content;

        &::before {
          background: linear-gradient(0deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
        }

        &::after {
          background: linear-gradient(180deg, rgba(245, 245, 245, 0) 0%, rgba(245, 245, 245, 1) 100%);
        }

        .exp__content {
          height: 80vh;
          padding: 0 10%;

          .exp__item {
            display: block;
            width: 100%;
            background-color: #fff;
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
            border-radius: 1.5rem;
            box-sizing: border-box;
            padding: 1rem;

            .exp__date {
              width: 100%;
              padding-right: 0;
              border-bottom: 2px solid #333;
              border-right: none;
            }

            .exp__description {
              padding-left: 0;
              width: 100%;
            }
          }
        }
      }
    }
  }
}