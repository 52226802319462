$primary: #fdc300;
$fontEn1: "Poppins", "Trebuchet MS";
$fontEn2: "Poiret One", cursive;
$fontEn3: "Pacifico", cursive;
$fontCn: "Noto Serif TC", serif;

// @import url("https://fonts.googleapis.com/css2?family=Noto+Serif+TC:wght@200;300;400;500;600;700;900&family=Pacifico&family=Poiret+One&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400&display=swap");

@font-face {
  font-family: Poppins;
  src: url(../assets/fonts/Poppins/Poppins-Regular.ttf);
}

@font-face {
  font-family: Pacifico;
  src: url(../assets/fonts/Pacifico/Pacifico-Regular.ttf);
}

@font-face {
  font-family: 'Noto Serif TC';
  src: url(../assets/fonts/Noto_Serif_TC/NotoSerifTC-Regular.otf);
}

@font-face {
  font-family: 'Poiret One';
  src: url(../assets/fonts/Poiret_One/PoiretOne-Regular.ttf);
}



.topic {
  font-size: 3rem;
  color: #333;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-family: $fontEn1;
  font-weight: 400;
}

%card-style {
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.12);
  // border-radius: 0.3125rem;
  border-radius: 1.5rem;
}

@media (max-width: 540px) {
  .topic {
    font-size: 2.5rem;
  }
}