.resume__sec {
  padding-top: 3.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .resume__opt {
    display: flex;
    justify-content: center;

    h1 {
      font-family: $fontCn;
      font-weight: 400;
      display: inline-block;
      padding-bottom: 0.5rem;
      margin: 2rem 2rem 0;
      cursor: pointer;
      transition: 0.5s;

      &:hover {
        color: $primary;
      }
    }
    .cvSelected {
      border-bottom: $primary solid 0.125rem;
      color: $primary;
    }
  }
  iframe {
    width: 80%;
    height: 100vh;
    margin: 1.5rem 0;
  }
}

@media (max-width: 540px) {
  .resume__sec {
    iframe {
      width: 90%;
    }
    .resume__opt {
      h1 {
        font-size: 1.5rem;
      }
    }
  }
}
