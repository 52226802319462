%skills-card {
  width: 100%;
  height: 100%;
  padding: 1.5rem 0;
  position: absolute;
  backface-visibility: hidden;
  transition: 0.5s;
}

%all-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.skills {
  height: auto;
  width: 100%;
  font-family: $fontEn1, $fontCn;
  color: #555;

  hr {
    margin-bottom: 0;
  }

  .skills__container {
    width: 100%;
    overflow: auto;
    padding: 1.5rem 10% 2.5rem;

    &::-webkit-scrollbar {
      display: none;
    }

    .skills__cards {
      display: flex;
      width: 108rem;
      margin: auto;

      .skills__category {
        width: 16rem;
        height: 20rem;
        cursor: pointer;
        transform: perspective(1000px);
        margin-right: 2rem;

        &:hover .skills__category--cover,
        .skills__category--back {
          box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
        }

        .skills__category--cover {
          @extend %card-style;
          @extend %skills-card;

          display: flex;
          justify-content: center;
          align-items: center;

          &:hover h2 {
            color: $primary;
          }

          h2 {
            font-weight: 500;
            font-size: 2rem;
            color: #222222;
            transition: 0.5s;
          }
        }

        .skills__category--back {
          @extend %card-style;
          @extend %skills-card;

          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          .skills__item {
            line-height: 2rem;
            font-size: 1.25rem;
            margin: 0.5rem 0;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;

            svg {
              margin: 0 0.25rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 540px) {
  .skills {
    margin-top: 5%;

    .skills__container {
      overflow: visible;

      .skills__cards {
        flex-direction: column;
        width: 100%;

        #en-skill {
          height: 10rem;
        }

        .skills__category {
          width: 100%;
          height: 25rem;
          margin-bottom: 1rem;

          .skills__category--cover {
            box-sizing: border-box;
          }

          .skills__category--back {
            box-sizing: border-box;

            .skills__item {
              margin: 1rem 0;
            }
          }
        }
      }
    }
  }
}