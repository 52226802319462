@import "./style/custom.scss";
@import "./style/common/navbar";
@import "./style/common/notFoundPage";
@import "./style/common/footer";
@import "./style/homepage";
@import "./style/works";
@import "./style/resume";
@import "./style/contact";

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

main {
  background-color: #f5f5f5;
}

hr {
  border: 0;
  height: 4px;
  background-color: $primary;
  width: 50px;
  margin: .5rem auto 1.875rem;
}