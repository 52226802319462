.menu {
  display: none;
}
.noscroll {
  overflow-y: hidden;
}
@media (max-width: 540px) {
  .menu {
    display: block;
    background-color: #fff;
    position: fixed;
    bottom: 0;
    height: calc(100vh - 3.125rem);
    width: 100vw;
    right: -100vw;
    z-index: 19;
    transition: 0.5s;
    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      padding: 0;
      margin: 0;
      font-family: $fontEn2;
      font-size: 3rem;

      li {
        margin: 0;
        list-style: none;
      }
      .menu__selected {
        color: $primary;
      }
    }
  }

  .menu__appear {
    right: 0;
  }
}
